<template>
  <div>
    <el-form size="small" class="query-form searchForm" label-width="90px" ref="searchForm" :model="inputForm">
      <el-form-item label="关键字：" v-show="favoritesType == 0" class="inputtew">
        <el-input style="width: 100%" size="small" v-model.trim="srhStr" placeholder="请输入藏品名称或资源名称"
                  clearable
                  maxlength="100"></el-input>
      </el-form-item>
      <el-form-item label="专题库名称" prop="thematicName" v-show="favoritesType == 1" class="inputtew">
        <el-input style="width: 100%" v-model.trim="inputForm.thematicName" clearable maxlength="20"
                  placeholder="请输入专题库名称(限20字)"></el-input>
      </el-form-item>
      <el-form-item label="所属类型" prop="thematicType" v-show="favoritesType == 1" class="inputtew">
        <el-select v-model="inputForm.thematicType"
                   style="width: 100%"
                   placeholder="请选择所属类型"
                   clearable>
          <el-option
              v-for="item in this.$dictUtils.getDictList('thematic_type')"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="favoritesType == 0" prop="createDate" label="收藏时间：" class="ingDatae2">
        <el-date-picker v-model="createDate" value-format="yyyy-MM-dd HH:mm:ss"
                        style="width: 100%"
                        format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange" clearable start-placeholder="开始时间"
                        end-placeholder="结束时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item v-else prop="createDate" label="收藏时间：" class="ingDatae">
        <el-date-picker v-model="createDate" value-format="yyyy-MM-dd HH:mm:ss"
                        style="width: 100%"
                        format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange" clearable start-placeholder="开始时间"
                        end-placeholder="结束时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label-width="15px" class="shaoufa">
        <el-button type="primary" @click="refreshList(1,1)" size="small" icon="el-icon-search">查询
        </el-button>
        <el-button @click="resetting()" size="small" icon="el-icon-refresh-right">重置</el-button>
      </el-form-item>
      <el-button class="f_r" type="primary" icon="el-icon-star-off" size="small" v-if="pageType != 'admin'"
                 @click="del()">取消收藏
      </el-button>
    </el-form>
    <div class="bg-white">
      <el-table v-show="favoritesType == 0"
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100vh - 400px)"
                ref="multipleTable1"
                @selection-change="selectionChangeHandle"
                class="table">
        <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50">
        </el-table-column>
        <el-table-column prop="createBy" label="收藏" v-if="pageType == 'admin'"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="createDate" label="收藏时间" sortable show-overflow-tooltip></el-table-column>
        <el-table-column prop="exhibitionId" label="图片" width="110">
          <template slot-scope="scope">
            <el-image fit="contain" :src="scope.row.thumb_path" class="itemImg"
                      style="width: 100px; height: 100px;">
              <el-image slot="placeholder" fit="contain" class="itemImg"
                        :src="require('@/assets/img/thumbnail.png')"
                        style="width: 100px; height: 100px"></el-image>
              <el-image slot="error" fit="contain" class="itemImg"
                        :src="require('@/assets/img/thumbnail.png')"
                        style="width: 100px; height: 100px"></el-image>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="helpOrgeneral_num" label="藏品编号"></el-table-column>
        <el-table-column show-overflow-tooltip prop="collection_name" label="藏品名称"></el-table-column>
        <el-table-column show-overflow-tooltip prop="resource_name" label="资源名称"></el-table-column>
        <el-table-column
            prop="type_name"
            label="类别" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="resource_keys"
            label="标签" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.resource_keys!=null&& scope.row.resource_keys.length > 0">
                            <span style="margin-right: 5px"
                                  v-for="(item,index) in scope.row.resource_keys">{{ item }};</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            width="150"
            label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" v-show="hasPermission('personal:collect:view')"
                       @click="view(scope.row)">详情
            </el-button>
            <el-button type="text" size="small" v-show="hasPermission('personal:collect:cancel')"
                       v-if="pageType != 'admin'"
                       @click="del(scope.row.id)">取消收藏
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-show="favoritesType == 1"
                :data="dataList"
                size="small"
                :row-key="'id'"
                @selection-change="selectionChangeHandle"
                v-loading="loading"
                ref="multipleTable"
                height="calc(100vh - 400px)"
                class="table">
        <el-table-column :reserve-selection="true" type="selection" width="50"/>
        <el-table-column prop="createUserName" label="收藏" v-if="pageType == 'admin'"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="createDate" label="收藏时间" sortable show-overflow-tooltip></el-table-column>
        <el-table-column prop="thematicName" label="专题库名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="description" label="专题库建设描述" show-overflow-tooltip></el-table-column>
        <el-table-column prop="thematicType" label="专题库所属类型" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $dictUtils.getDictLabel("thematic_type", scope.row.thematicType, '-') }}
          </template>
        </el-table-column>
        <el-table-column prop="ownership" label="专题库归属" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.ownership == 0 ? '全馆' : scope.row.ownership == 1 ? '部门选择' : '个人选择' }}
          </template>
        </el-table-column>
        <el-table-column prop="resourceNum" label="资源数量" show-overflow-tooltip></el-table-column>
        <el-table-column
            fixed="right"
            width="200"
            label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small"
                       @click="resource(scope.row)"
                       v-show="hasPermissionButton(`personal:collect:resource`)">资源列表
            </el-button>
            <el-button type="text" size="small" v-show="hasPermission('personal:collect:cancel')"
                       v-if="pageType != 'admin'"
                       @click="del(scope.row.id)">取消收藏
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page.sync="pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="pageSize"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <coll-list ref="collList" @refresh="refreshList()"></coll-list>
  </div>
</template>

<script>
import CollList from "@/views/modules/dyyg/libraryManagement/collList.vue";

export default {
  props: ['pageType', 'favorites'],
  components: {CollList},
  data() {
    return {
      srhStr: '',
      favoritesType: '0',
      dataList: [],
      pageNo: 1,
      pageNo2: '',
      pageSize: 10,
      total: 0,
      dataListSelections: [],
      loading: false,
      countInfo: {
        archives: 0,
        collection: 0,
      },

      inputForm: {
        thematicName: '',
        thematicType: '',
        startTime: '',
        entTime: '',
      },
      createDate: [],
    }
  },

  mounted() {
    let homeSetOpt = JSON.parse(sessionStorage.getItem('homeSetOpt'))
    if (homeSetOpt) {
      this.$refs.collList.init('', '', '',homeSetOpt, null, 'collectionCenter')
      sessionStorage.removeItem('homeSetOpt')
    }
    let srhData = JSON.parse(sessionStorage.getItem('listSearch'))
    if (srhData != null && srhData) {
      this.srhStr = srhData.srhStr
      this.pageNo2 = parseInt(srhData.pageNo);
    }
    this.refreshList()
  },

  methods: {
    // 查询
    refreshList(type, dividePage) {
      this.$emit('refresh')
      if (type == 1) {
        this.pageNo = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable1.clearSelection()
      }
      this.loading = true
      let identification = '1'
      if (this.pageType === 'admin') {
        identification = '0'
      }
      if (this.favoritesType == 0) {
        let startTime, entTime
        if (this.createDate) {
          startTime = this.createDate[0]
          entTime = this.createDate[1]
        } else {
          startTime = ''
          entTime = ''
        }
        this.$axios(this.api.rcPage.homeCollectionList, {
          'current': this.pageNo2 || this.pageNo,
          'size': this.pageSize,
          'name': this.srhStr,
          startTime,
          entTime,
          'favoritesType': this.favoritesType,
          'identification': identification
        }, 'get').then(res => {
          if (res.status) {
            this.total = parseInt(res.data.total);
            if (this.pageNo2) {
              this.pageNo = this.pageNo2
              this.pageNo2 = ''
            }
            this.dataList = res.data.records;
            if (this.dataList.length == 0 && this.pageNo > 1) {
              this.pageNo--;
              this.refreshList()
            }
          }
          this.loading = false
        })
      } else {
        if (this.createDate && this.createDate.length > 0) {
          this.inputForm.startTime = this.createDate[0]
          this.inputForm.entTime = this.createDate[1]
        } else {
          this.inputForm.startTime = ''
          this.inputForm.entTime = ''
        }
        console.log(this.inputForm);
        this.$axios(this.api.original.myCollection, {
          ...this.inputForm,
          'current': this.pageNo,
          'size': this.pageSize,
          'identification': identification
        }, 'get').then(res => {
          if (res.status) {
            this.total = parseInt(res.data.total);
            this.dataList = res.data.records;
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout()
            })
            if (this.dataList.length == 0 && this.pageNo > 1) {
              this.pageNo--;
              this.refreshList()
            }
          }
          this.loading = false
        })
      }
    },

    resetting() {
      this.$refs.multipleTable.clearSelection()
      this.$refs.multipleTable1.clearSelection()
      this.createDate = null
      if (this.favoritesType == 0) {
        this.srhStr = ''
      } else {
        this.$refs.searchForm.resetFields()
      }
      this.refreshList(1, 1)
    },

    //资源管理
    resource(row) {
      let opt = {
        srhStr: this.srhStr,
        pageNo: this.pageNo,
        favoritesType: this.favorites,
      }
      sessionStorage.setItem('listSearch', JSON.stringify(opt))

      this.$axios(this.api.original.myCollectionResource + row.id, {}, 'get').then(res => {
        if (res.status) {
          let searchData = {
            current: this.current,
            size: this.size,
            ...this.inputForm,
          }
          this.$refs.collList.init(1, row, searchData, null, 'collectionCenter')
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 详情
    view(row) {
      let opt = {
        srhStr: this.srhStr,
        pageNo: this.pageNo,
        favoritesType: this.favorites,
      }
      sessionStorage.setItem('listSearch', JSON.stringify(opt))
      var newRow = row
      newRow.id = newRow.foreign_key_id
      if (this.pageType != 'admin') {
        sessionStorage.setItem('dizhi', '/personal/collect/list')
      } else {
        sessionStorage.setItem('dizhi', '/personal/collectionCenter')
      }
      this.$router.push({
        path: '/dyyg/home/retrievedDetails',
        query: newRow
      });
    },
    // 移出收藏
    del(id) {
      let ids = [id];
      if (!id && !this.dataListSelections.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      if (!id) {
        ids = this.dataListSelections.map(item => {
          return item.id
        })
      }
      this.$confirm(`确定取消收藏所选项吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.favoritesType == 0) {
          this.$axios(this.api.rcPage.collectionRemoveByIds, ids, 'post').then(data => {
            if (data && data.status) {
              this.$message.success('取消成功')
              this.refreshList('', 1)
            } else {
              this.$message.error('取消失败')
            }
          })
        } else {
          this.$axios(this.api.original.thematiclibraryRemoveCollection, {ids: ids}, 'delete').then(data => {
            if (data && data.status) {
              this.$message.success('移除成功')
              this.refreshList('', 1)
            } else {
              this.$message.error('取消失败')
            }
          })
        }
      })
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.refreshList(1)
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },

    search(favoritesType) {
      if (favoritesType) {
        this.favoritesType = favoritesType - 2
      }
      this.refreshList(1, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.searchForm {
  .inputtew.el-form-item--small.el-form-item {
    display: inline-block;
    width: 27%;
  }

  .ingDatae.el-form-item--small.el-form-item {
    display: inline-block;
    width: 40%;
  }

  .ingDatae2.el-form-item--small.el-form-item {
    display: inline-block;
    width: 30%;
  }

  .shaoufa.el-form-item--small.el-form-item {
    display: inline-block;
    min-width: 20%;
  }
}
</style>
